// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React from 'react';
import ReactLoading from 'react-loading';

const types = ['balls', 'bars', 'bubbles', 'cubes', 'cylon', 'spin', 'spinningBubbles', 'spokes'];

export default class Portal extends React.Component {

    state = {
        type: 'spinningBubbles',
        width: 240,
        height: 240,
        color: 'rgb(81,154,210)',
        backgroundColor: 'rgba(0,0,0,0.3)'
    }

    getType = () => {
        if (this.props.randomize) {
            const idx = Math.floor(Math.random() * types.length);
            return types[idx];
        } else {
            return this.state.type
        }
    }

    render = () => {
        return (
            <div 
                className='loadingOverlayComponent'
                style={{
                    position: 'fixed',
                    width: 'calc(100% - 240px)',
                    height: '100%',
                    left: '240px',
                    top: 0,
                    zIndex: 98,
                    backgroundColor: this.state.backgroundColor,
                }}
            >
                <div 
                    className='loadingOverlayGraphic'
                    style={{
                        position: 'absolute',
                        left: 'calc(50% - ' + this.state.width / 2 + 'px)',
                        top: 'calc(50% - ' + this.state.height / 2 + 'px)',
                        zIndex: 99,
                    }}
                >
                    <ReactLoading type={this.getType()} width={this.state.width} height={this.state.height} color={this.state.color} />
                </div>
            </div>
        )
    }
}