
export const documents = [
    {
        name: 'My document 1',
        uploadDate: 'Document uploaded on 07-13-2020',
        flagIcon: 'flag.svg',
        thumbnailImg: 'document_thumbnail.png',
        flagged: false,
        numPages: 3,
    },
    {
        name: 'My document 2',
        uploadDate: 'Document uploaded on 07-13-2020',
        flagIcon: 'flag.svg',
        thumbnailImg: 'document_thumbnail.png',
        flagged: false,
        numPages: 3,
    },
    {
        name: 'My document 3',
        uploadDate: 'Document uploaded on 07-13-2020',
        flagIcon: 'flag.svg',
        thumbnailImg: 'document_thumbnail.png',
        flagged: false,
        numPages: 3,
    },

    {
        name: 'My document 3',
        uploadDate: 'Document uploaded on 07-13-2020',
        flagIcon: 'flag.svg',
        thumbnailImg: 'document_thumbnail.png',
        flagged: false,
        numPages: 3,
    },

    {
        name: 'My document 3',
        uploadDate: 'Document uploaded on 07-13-2020',
        flagIcon: 'flag.svg',
        thumbnailImg: 'document_thumbnail.png',
        flagged: false,
        numPages: 3,
    },

    {
        name: 'My document 3',
        uploadDate: 'Document uploaded on 07-13-2020',
        flagIcon: 'flag.svg',
        thumbnailImg: 'document_thumbnail.png',
        flagged: false,
        numPages: 3,
    }]

export const claims = [
    {
        claimId: 1,
        claimNumber: '000001',
        employeeId: 1,
        planId: 1,
        for: '',
        status: 'Complete',
        assignee: null,
        flag: false,
        documents: [
            {
                name: 'My document 1',
                uploadDate: 'Document uploaded on 07-13-2020',
                flagIcon: 'flag.svg',
                thumbnailImg: 'document_thumbnail.png',
                flagged: false,
                numPages: 3,
            },
            {
                name: 'My document 2',
                uploadDate: 'Document uploaded on 07-13-2020',
                flagIcon: 'flag.svg',
                thumbnailImg: 'document_thumbnail.png',
                flagged: false,
                numPages: 3,
            },
            {
                name: 'My document 3',
                uploadDate: 'Document uploaded on 07-13-2020',
                flagIcon: 'flag.svg',
                thumbnailImg: 'document_thumbnail.png',
                flagged: false,
                numPages: 3,
            },

            {
                name: 'My document 3',
                uploadDate: 'Document uploaded on 07-13-2020',
                flagIcon: 'flag.svg',
                thumbnailImg: 'document_thumbnail.png',
                flagged: false,
                numPages: 3,
            },

            {
                name: 'My document 3',
                uploadDate: 'Document uploaded on 07-13-2020',
                flagIcon: 'flag.svg',
                thumbnailImg: 'document_thumbnail.png',
                flagged: false,
                numPages: 3,
            },

            {
                name: 'My document 3',
                uploadDate: 'Document uploaded on 07-13-2020',
                flagIcon: 'flag.svg',
                thumbnailImg: 'document_thumbnail.png',
                flagged: false,
                numPages: 3,
            }

        ]
    },
    {
        claimId: 2,
        claimNumber: '000002',
        employeeId: 2,
        planId: 1,
        for: '',
        status: 'Pending',
        assignee: null,
        flag: false,
    },
    {
        claimId: 3,
        claimNumber: '000003',
        employeeId: 2,
        planId: 2,
        for: '',
        status: 'Pending',
        assignee: null,
        flag: false,
    },
    {
        claimId: 4,
        claimNumber: '000004',
        employeeId: 3,
        planId: 3,
        for: '',
        status: 'Pending',
        assignee: null,
        flag: false,
    },
    {
        claimId: 5,
        claimNumber: '000005',
        employeeId: 4,
        planId: 3,
        for: '',
        status: 'Complete',
        assignee: null,
        flag: false,
    },
    {
        claimId: 6,
        claimNumber: '000006',
        employeeId: 5,
        planId: 3,
        for: '',
        status: 'Pending',
        assignee: null,
        flag: false,
    },
    {
        claimId: 7,
        claimNumber: '000007',
        employeeId: 6,
        planId: 3,
        for: '',
        status: 'Pending',
        assignee: null,
        flag: false,
    },
    {
        claimId: 8,
        claimNumber: '000008',
        employeeId: 6,
        planId: 4,
        for: '',
        status: 'Pending',
        assignee: null,
        flag: false,
    },
    {
        claimId: 9,
        claimNumber: '000009',
        employeeId: 6,
        planId: 4,
        for: '',
        status: 'Pending',
        assignee: null,
        flag: false,
    },
    {
        claimId: 10,
        claimNumber: '000010',
        employeeId: 6,
        planId: 4,
        for: '',
        status: 'Pending',
        assignee: null,
        flag: false,
    },
    {
        claimId: 11,
        claimNumber: '000011',
        employeeId: 6,
        planId: 4,
        for: '',
        status: 'Pending',
        assignee: null,
        flag: false,
    },
    {
        claimId: 12,
        claimNumber: '000012',
        employeeId: 6,
        planId: 4,
        for: '',
        status: 'Pending',
        assignee: null,
        flag: false,
    },
    {
        claimId: 13,
        claimNumber: '000013',
        employeeId: 6,
        planId: 4,
        for: '',
        status: 'Pending',
        assignee: null,
        flag: false,
    },
    {
        claimId: 14,
        claimNumber: '000014',
        employeeId: 6,
        planId: 4,
        for: '',
        status: 'Pending',
        assignee: null,
        flag: false,
    },
    {
        claimId: 15,
        claimNumber: '000015',
        employeeId: 6,
        planId: 4,
        for: '',
        status: 'Pending',
        assignee: null,
        flag: false,
    },
]


export const employers = [
    {
        id: 1,
        name: 'Wendys',
    },
    {
        id: 2,
        name: 'Burger King',
    }
]

export const plans = [
    {
        id: 1,
        employerId: 1,
        name: 'Plan 1',
        code: '000001',
    },
    {
        id: 2,
        employerId: 1,
        name: 'Plan 2',
        code: '000002',
    },
    {
        id: 3,
        employerId: 2,
        name: 'Plan 3',
        code: '000003',
    },
    {
        id: 4,
        employerId: 2,
        name: 'Plan 4',
        code: '000004',
    }
]

export const employees = [
        {
            id: 1,
            name: 'John',
            email: 'john@email.com',
            password: 'pass',
            employerId: 1,
            planId: 1,
        },
        {
            id: 2,
            name: 'Derek',
            email: 'derek@email.com',
            password: 'pass',
            employerId: 1,
            planId: 1,
        },
        {
            id: 3,
            name: 'Chase',
            email: 'chase@email.com',
            password: 'pass',
            employerId: 2,
            planId: 2,
        },
        {
            id: 4,
            name: 'Clay',
            email: 'clay@email.com',
            password: 'pass',
            employerId: 2,
            planId: 2,
        },
        {
            id: 5,
            name: 'Andrew',
            email: 'andrew@email.com',
            password: 'pass',
            employerId: 2,
            planId: 3,
        },
        {
            id: 6,
            name: 'Luke',
            email: 'luke@email.com',
            password: 'pass',
            employerId: 2,
            planId: 3,
        }
    ]

export const operators = [
    {
        id: 1,
        name: 'Luke',
    },
    {
        id: 2,
        name: 'Chase',
    },
    {
        id: 3,
        name: 'Clay',
    },
    {
        id: 4,
        name: 'John',
    },
    {
        id: 5,
        name: 'Andrew',
    },
    {
        id: 6,
        name: 'Derek'
    },
]

export const notifications = [
    {
        name: 'Johnny B Good',
        type: 'newClaim'
    },
    {
        name: 'Sarah Jones',
        type: 'helpRequest'
    },
    {
        name: 'Sarah Jones',
        type: 'viewedReceipt'
    },
    {
        name: 'Luke B',
        type: 'viewedReceipt'
    }
]