// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React, { Fragment } from 'react';
import '../../styles/multiSlider.css'

import { Slider, Rail, Handles, Tracks } from 'react-compound-slider';

export default class MultiSlider extends React.Component {

    state = {
        currentRange: []
    }

    componentDidMount = async () => {
        this.setState({currentRange: this.props.domain})
    }

    onUpdate = (newRange) => {
        this.setState({currentRange: newRange})
        this.props.onUpdate(newRange);
    }

    // sub components
    
    SliderRail = ({ getRailProps }) => {
        return (
            <Fragment>
                <div className='railOuter' />
                <div className='railInner' />
            </Fragment>
        );
    }
    
    Handle = ({
        domain: [min, max],
        handle: { id, value, percent },
        disabled,
        getHandleProps,
        handleValue
    }) => {
        return (
            <Fragment>
                <div
                    style={{
                        left: `${percent}%`,
                        position: "absolute",
                        transform: "translate(-50%, -50%)",
                        WebkitTapHighlightColor: "rgba(0,0,0,0)",
                        zIndex: 5,
                        width: 30,
                        height: 30,
                        cursor: "pointer",
                        backgroundColor: "none",                        
                    }}
                    {...getHandleProps(id)}
                ></div>
                <div
                    role="slider"
                    aria-valuemin={min}
                    aria-valuemax={max}
                    aria-valuenow={value}
                    style={{
                        left: `${percent}%`,
                        position: "absolute",
                        transform: "translate(-50%, -50%)",
                        zIndex: 2,
                        width: 20,
                        height: 20,
                        borderRadius: "15px",
                        boxShadow: "1px 1px 1px 1px rgba(0, 0, 0, 0.3)",
                        backgroundColor: disabled ? "#666" : "rgb(140,140,140)",
                        
                    }}
                ><span style={{ left: '5px', top: '25px', position: 'relative' }}>{handleValue}</span></div>
            </Fragment>
        );
    }

    Track = ({ source, target, getTrackProps, disabled }) => {
        return (
            <div
                style={{
                    position: "absolute",
                    transform: "translate(0%, -50%)",
                    height: '2px',
                    zIndex: 1,
                    backgroundColor: disabled ? "#999" : "rgb(200,200,200)",
                    borderRadius: 7,
                    cursor: "pointer",
                    left: `${source.percent}%`,
                    width: `${target.percent - source.percent}%`,
                    
                }}
                {...getTrackProps()}
            />
        );
    }

    // styles

    sliderStyle = {
        position: "relative",
        width: "70%",
        left: "15%",
        top: "calc(50%)",
        margin: "0",
        backgroundColor: "yellow",
    };

    render = () => {

        return (
            <div className='multiSliderComponent'>

                {/* left and right min/max labels */}
                <div className='rangeMinLabel'>
                    {this.props.domain[0]}
                </div>
                <div className='rangeMaxLabel'>
                    {this.props.domain[1]}
                </div>

                <Slider
                    mode={2}
                    step={1}
                    domain={this.props.domain}
                    rootStyle={this.sliderStyle}
                    onUpdate={this.onUpdate}
                    onChange={this.onChange}
                    values={this.props.domain}
                >
                    <Rail>
                        {({ getRailProps }) => <this.SliderRail getRailProps={getRailProps} />}
                    </Rail>
                    <Handles>
                        {({ handles, getHandleProps }) => (
                            <div className="slider-handles">
                                {handles.map((handle, idx) => (
                                    <this.Handle
                                        key={handle.id}
                                        handle={handle}
                                        domain={this.props.domain}
                                        getHandleProps={getHandleProps}
                                        handleValue={this.state.currentRange[idx]}
                                    />
                                ))}
                            </div>
                        )}
                    </Handles>
                    <Tracks left={false} right={false}>
                        {({ tracks, getTrackProps }) => (
                            <div className="slider-tracks">
                                {tracks.map(({ id, source, target }) => (
                                    <this.Track
                                        key={id}
                                        source={source}
                                        target={target}
                                        getTrackProps={getTrackProps}
                                    />
                                ))}
                            </div>
                        )}
                    </Tracks>
                </Slider>
            </div>
        )
    }
}

