// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import api from '../api';

const links = [
    {
        icon: 'dashboard.png',
        title: 'Dashboard',
        to: '/',
        exact: true
    },
    {
        icon: 'employers.png',
        title: 'Employers',
        to: '/employers'
    },
    {
        icon: 'claims.png',
        title: 'Claims',
        to: '/claims'
    }
];

class Navbar extends React.Component {

    logout = async () => {
        api.post('self/logout')
        this.props.history.push('/login');
    }

    render = () => {
        
        return (<nav>
            {/* FBC Logo */}
            <img
                className="logo"
                src="/logo/logo.png"
                alt="FBC"
            />

            {links.map((link,idx) => <NavLink
                key={idx}
                to={link.to}
                exact={link.exact}
                className="nav-link"
                activeClassName="nav-link-active"
            >
                <img
                    src={"/icons/" + link.icon}
                    alt={link.title}
                />
                {link.title}
            </NavLink>)}

            <div
                className="nav-link nav-logout"
                onClick={this.logout}
            >
                <img
                    src={"/icons/logout.png"}
                    alt="Log out"
                />
                Log out
            </div>

        </nav>)
    }

};

export default withRouter(Navbar);