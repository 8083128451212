// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React from 'react';

import delay from "delay";
import api from '../api';

import '../styles/search.css';

export default class Search extends React.Component {

    state = {
        query: "",
        results: {},
        haveResults: false,
        failedSearch: false,
    }

    determineIfSearch = async startingSearch => {

        this.setState({failedSearch: false, haveResults: false})

        await delay(1000)

        if (startingSearch && startingSearch !== "" && startingSearch === this.state.query)
        {

            // Let the api process this request
            let res = await api.post("self/search", {query: this.state.query})
            if (res)
                this.setState({results: res, haveResults: true})
            else {
                this.setState({haveResults: false, failedSearch: true})
            }

        }
        else { this.setState({haveResults: false, failedSearch: false}) }
        
    }

    render = () => {

        return (
            <div className="search">
                <div className='searchContainer'>
                    <input
                        type="text"
                        value={this.state.query}
                        onChange={e => {
                            this.setState({ query: e.target.value });
                            this.determineIfSearch(e.target.value);
                        }}
                        placeholder="Search..."
                        onKeyDown={e => {
                            if (e.keyCode === 27)
                                this.setState({query: '', haveResults: false, results: {}})
                        }}
                    />
                    <div className="search-icon">
                        <img
                            src="/icons/search.png"
                            alt="Search"
                        />
                    </div>

                    {this.state.haveResults && <div className="search-results">
                        {Object.keys(this.state.results).map((key, value) => {

                            if (this.state.results[key].length > 0)
                                return (
                                    <div className="search-header" key={key}>
                                        <div className="search-icon"><img src="/icons/search.png" alt="search-logo"/></div>
                                            {this.state.results[key].map((object, idx) => {

                                                let display = object.name
                                                if (key === "claims")
                                                    display = object.claimNumber
                                                
                                                // Create the onclick functions for each of these
                                                let searchClick = async () => {

                                                    this.setState({results: { }, haveResults: false})
                                                    let splitURL = window.location.href.split("/")
                                                    let currentLocation = splitURL[splitURL.length - 1]
                                                    let lastLocation = splitURL[splitURL.length - 2]

                                                    if (key === "claims") {
                                                        this.props.history.push("/claim/" + object._id);
                                                        this.setState({query: ''})
                                                        return
                                                    }
                                                    else if (key === "employees") {

                                                        if (currentLocation === "claims") {
                                                            await this.props.history.push("/")
                                                        }

                                                        this.props.setEmployeeIdForClaims(object._id)
                                                        this.props.history.push("/claims");
                                                        this.setState({query: ''})
                                                        return
                                                    }
                                                    else if (key === "employers") {
                                                        this.props.history.push("/employer/" + object._id);
                                                        this.setState({query: ''})
                                                        return
                                                    }
                                                    else if (key === "plans") {

                                                        if (lastLocation === "employer") {
                                                            await this.props.history.push("/")
                                                        }

                                                        this.props.history.push("/employer/" + object.employer);
                                                        this.setState({query: ''})
                                                        return
                                                    }

                                                }

                                                return (
                                                    <div className={'search-card'} key={idx} onClick={searchClick}>
                                                        {idx === 0 ? <span className='search-card-type'>{key}</span> : ''}
                                                        <span className='search-card-value'>{display}</span>
                                                    </div>
                                                )

                                            })
                                        }
                                    </div>
                                )
                            else { return null }
                        })}
                    </div>}

                    {(!this.state.haveResults && this.state.query !== "") && <div className="search-results">
                        <div className='search-card'>
                            <div className="search-card-type">
                                {this.state.failedSearch ? "No results" : "Searching..."}
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
        );
    }

}