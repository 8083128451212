// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import HttpsRedirect from 'react-https-redirect';

// Components
import Portal from './components/Portal';
import Login from './components/Login';

// import api from './api'

// Style(s)
import './style.css';

export default class App extends React.Component {

    // Local state
    state = {
        loading: false
    }

    // On load
    componentDidMount = async () => {
        // console.log('h1n1 ')
        // const res = await api.get('employee/get', {}, true)
        // console.log(res)
    }

    // Loading controller
    loading = async () => await this.setState({ loading: true });
    loaded = () => setTimeout(() => this.setState({ loading: false }), 250);

    // Render function
    render = () => {
        return (<>
            <HttpsRedirect>
                <Router>                    

                    {/* Page controller (switch) */}
                    <Switch>

                        {/* Login */}
                        <Route
                            path="/login" exact
                            render={props => <Login {...props} />}
                        />

                        {/* All other routes */}
                        <Route
                            // default
                            render={props => <Portal
                                {...props}
                                user={this.state.user}
                                loading={this.loading}
                                loaded={this.loaded}
                            />}
                        />

                    </Switch>

                </Router>
            </HttpsRedirect>
        </>);
    };
    
};