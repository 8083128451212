import React from 'react';
import ReactLoading from 'react-loading';

export const promptForDelete = (name, alertAfterward=false) => {
    const res = prompt('Be careful!  This object will be permanantly deleted.\nType \'' + name + '\' to proceed.')
    if (res && res.toUpperCase() === name) {
        if (alertAfterward)
            alert('\'' + name + '\' will be deleted');
        return true;
    } else {
        if (alertAfterward)
            alert('The text entered, \'' + res + '\', does not match \'' + name + '\'.\nIt will not be deleted.');
        return false;
    }
}

export const normalizeDomain = (domain) => {
    if (domain[0] === domain[1])
        return [domain[0], domain[0] + 1]
    return domain

}

export const getLoadingGraphic = (width, height, type = 'spinningBubbles', color = 'rgb(39,66,124)') => {
    const left = 'calc(50% - ' + String(width / 2) + 'px)';
    return (
        // <div style={{ position: 'relative', left: {left} }}>
            <ReactLoading type={type} width={width} height={height} color={color} />
        // </div>
    )
}