// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React from 'react';

import '../../styles/doubleInput.css'

export default class DoubleInput extends React.Component {

    render = () => {
        return (
            <div className='doubleInputContainer'>
                <div className='doubleInput'>
                    <input
                        onChange={evt => this.props.onChange1(evt)}
                        placeholder={this.props.placeholder1 ? this.props.placeholder1 : ''}
                    />  
                </div><span style={{fontSize: '24px', fontWeight: 'bolder', marginLeft: '3%', marginRight: '3%'}}>:</span>
                <div className='doubleInput'>
                    <input
                        onChange={evt => this.props.onChange2(evt)}
                        placeholder={this.props.placeholder2 ? this.props.placeholder2 : ''}
                    />  
                </div>
            </div>
        )
    }
}