import React, {Component} from 'react'
import Dropzone from 'react-dropzone'

import '../../styles/dragAndDrop.css';

export default class DragAndDrop extends Component {
    state = {
        isDragging: false,
        outCount: 0,
    }

    componentDidUpdate = prevProps => {
        // if (!prevProps.dragging && this.props.dragging === false && this.state.isDragging === true)
        //     this.setState({isDragging: false})
    }

    preventDefault = e => {
        e.preventDefault();
        e.stopPropagation();
    }
    
    handleDragOver = e => {
        this.preventDefault(e);
        this.setState({isDragging: true})
    };

    handleDragEnter = async e => {
        this.preventDefault(e);
        this.setState({ isDragging: true })
    };

    handleDragLeave = async e => {
        // this.preventDefault(e);
        // this.setState({ isDragging: false})
    };

    handleMouseLeave = async e => {
        this.preventDefault(e);
        this.setState({ isDragging: false, outCount: this.state.outCount + 1 })
    };

    handleMouseEnter = async e => {
        // this.setState({ isDragging: true, outCount: this.state.outCount + 1 })
    };

    handleMouseUp = async e => {
        // this.preventDefault(e);
        // this.setState({ isDragging: this.state.outCount > 1 ? false : true, outCount: this.state.outCount + 1 })
    };

    handleMouseOut = async e => {
        // this.preventDefault(e);
        // this.setState({ isDragging: this.state.outCount > 1 ? false : true, outCount: this.state.outCount + 1 })
    };

    handleDrop = (e) => {
        this.preventDefault(e);
        const file = e.dataTransfer.files[0];
        this.setState({isDragging: false})
        if (file.type !== 'application/pdf') {

        } else {
            this.props.onDrop(file)
        }
    };

    getStyle = () => {
        let style = {};
        style.position = 'relative';
        style.width = '100%';
        style.height = '100%';
        if (this.state.isDragging) {
            // style.cursor = 'cursor';
        } else {
            // style.cursor = 'cursor';
        }
        return style;
    }

    render = () => {
        
        return (
            <div 
                style={this.getStyle()}
                ref='dragAndDropComponent'
                className='dragAndDropComponent'
                
                // onDrop={e => this.handleDrop(e)}

                onDragOver={e => this.handleDragOver(e)}

                onDragEnter={e => this.handleDragEnter(e)}
                onDragLeave={e => this.handleDragLeave(e)}
                
                onMouseEnter={e => this.handleMouseEnter(e)}
                onMouseLeave={e => this.handleMouseLeave(e)}
                onMouseUp={e => this.handleMouseUp(e)}
                onMouseOut={e => this.handleMouseOut(e)}

            >
                {/* {this.props.children} */}

                {this.state.isDragging && <div 
                    onDrop={e => this.handleDrop(e)} 
                    style={{zIndex: 5000, padding: '30px', position: 'absolute', left: '-30px', top: '-45px', width: '100%', height: '100%', borderRadius: '15px', border: '2px dotted black', backgroundColor: 'rgba(255,255,255,0.7)'}}
                >
                    <img 
                        style={{ position: 'relative', width: '25vh', height: '25vh', left: 'calc(50% - 25vh/2)', top: 'calc(50% - 25vh/2)'}}
                        src='/icons/dragDrop.svg'
                    />
                </div>}
            </div>
        );
    }
}