// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React from 'react';
import { Route } from 'react-router-dom';

// Components
import Navbar from '../Navbar';
import Search from '../Search';
import LoadingOverlay from '../LoadingOverlay';
import Dashboard from './Dashboard';
import Employers from './Employers';
import Claims from './Claims';
import ManageEmployer from './ManageEmployer';
import Claim from './Claim';

import api from "../../api";

import { documents, notifications } from '../../constants/data';
// import { claims as allClaims, employers, plans, employees, operators, notifications } from '../../constants/data'

export default class Portal extends React.Component {

    state = {
        loadingOverlay: false,

        loaded: false,

        // data loaded from db
        employers: [],
        employees: [],
        claims: [],
        plans: [],
        operators: [],

        // lists compiled from all data
        claimsList: [],
        employerList: [],
        employeeList: [],

        // specific employee for claims page
        employeeIdForClaims: null,
    }

    // children data refresh rate (in milliseconds)
    refreshInterval = 60000; 

    componentDidMount = async () => {
        // await loadDataIntoDatabase();
        // await this.loadData();

        this.fetchOperators();
    }

    fetchOperators = async () => {
        const allOperators = await api.get('operator/getbyquery', { query: {} }).catch(err => console.log('err getting Operators:', err));
        this.setState({operators: allOperators});
    }

    setEmployeeIdForClaims = async (id = null) => {
        this.setState({ employeeIdForClaims: id })
    }

    // updateList includes any of the following: ['employers','employees','claims','plans','operators']
    loadData = async (updateList=[]) => {
        console.log('loading data...', updateList)
        let allEmployers = this.state.employers;
        let allEmployees = this.state.employees;
        let allPlans = this.state.plans;
        let allClaims = this.state.claims;
        let allOperators = this.state.operators;

        if (updateList.length === 0 || updateList.includes('employers'))
            allEmployers = await api.get('employer/getbyquery', { query: {} }).catch(err => console.log('err getting employers:', err))
        if (updateList.length === 0 || updateList.includes('employees'))
            allEmployees = await api.get('employee/getbyquery', { query: {} }).catch(err => console.log('err getting employees:', err))
        if (updateList.length === 0 || updateList.includes('claims'))
            allClaims = await api.get('claim/getbyquery', { query: {} }).catch(err => console.log('err getting Claims:', err))
        if (updateList.length === 0 || updateList.includes('plans'))
            allPlans = await api.get('plan/getbyquery', { query: {} }).catch(err => console.log('err getting Plans:', err))
        if (updateList.length === 0 || updateList.includes('operators'))
            allOperators = await api.get('operator/getbyquery', { query: {} }).catch(err => console.log('err getting Operators:', err))

        // console.log('allEmployers:', allEmployers)
        // console.log('allEmployees:', allEmployees)
        // console.log('allPlans:', allPlans)
        // console.log('allClaims:', allClaims)
        // console.log('allOperators:', allOperators)

        await this.setState({
            employers: allEmployers,
            employees: allEmployees,
            claims: allClaims,
            plans: allPlans,
            operators: allOperators,
            loaded: true,
        });

        this.getClaimsList();
        this.getEmployeesList();

    }

    getClaimsList = async () => {
        let claimsList = [];

        if (!this.state.claims || this.state.claims.length === 0)
            return claimsList;

        try {
            for (let i = 0; i < this.state.claims.length; i++) {

                const claim = {...this.state.claims[i]};
                const employee = this.state.employees.filter(emp => emp._id === claim.employee)[0];
                const employer = this.state.employers.filter(emp => emp._id === employee.employer)[0];
                const plan = this.state.plans.filter(plan => plan._id === claim.plan)[0];
                const operator = claim.operator ? this.state.operators.filter(op => op._id === claim.operator)[0] : undefined;

                claimsList.push({
                    claimId: claim._id,
                    employer: employer.name,
                    plan: plan.name,
                    employee: employee.name,
                    employeeId: employee._id,
                    status: claim.status,
                    operator: operator ? operator.name : undefined,
                    employeeEmail: employee.email,
                })
            }
        }
        catch (err) {
            console.log('Error creating claims list. ', err)
        }
        
        this.setState({claimsList: claimsList});
    }

    getEmployeesList = async () => {
        let employeeList = [];

        if (!this.state.employees || this.state.employees.length === 0)
            return employeeList;

        try {
            for (let i = 0; i < this.state.employees.length; i++) {

                const empClaims = this.state.claims.filter(c => c.employee === this.state.employees[i]._id);
                const numClaims = empClaims.length;
                const status = empClaims.filter(c => c.status === 'Pending').length > 0 ? 'Pending' : 'Complete';
                const employerId = this.state.employees[i].employer;

                employeeList.push({
                    employeeName: this.state.employees[i].name,
                    numClaims: numClaims,
                    status: status,
                    employerId: employerId,
                    employeeId: this.state.employees[i]._id,
                })
            }
        }
        catch (err) {
            console.log('Error creating employees list:', err)
        }

        this.setState({employeeList: employeeList});
    }

    getDummyClaim = () => {
        return {
            claimId: '12345',
            claimNumber: '12345',
            employeeName: 'Tom',
            documents: documents,
        }
    }

    setLoadingState = state => {
        this.setState({loadingOverlay: state})
    }

    render = () => {
        return (<>
            
            {/* Navigation bar */}
            <Navbar />

            {/* Search bar */}
            <Search history={this.props.history} setEmployeeIdForClaims={this.setEmployeeIdForClaims} />

            {this.state.loadingOverlay && <LoadingOverlay />}

            <div className="portal-container">

                <Route
                    path="/" exact
                    render={props => <Dashboard
                        {...props}
                        notifications={notifications}
                        claims={this.state.claimsList}
                        operators={this.state.operators}
                        reloadOperators={this.fetchOperators}
                        refreshInterval={this.refreshInterval}
                        setLoadingState={this.setLoadingState}
                    />}
                />

                <Route
                    path="/employers" exact
                    render={props => <Employers
                        {...props}
                        employers={this.state.employerList}
                        refreshInterval={this.refreshInterval}
                        setLoadingState={this.setLoadingState}
                    />}
                />

                <Route
                    path="/employer/:employerId" exact
                    render={props => <ManageEmployer
                        {...props}
                        employers={this.state.employerList}
                        employees={this.state.employeeList}
                        setEmployeeIdForClaims={this.setEmployeeIdForClaims}
                        refreshInterval={this.refreshInterval}
                        setLoadingState={this.setLoadingState}
                    />}
                />

                <Route
                    path="/claims" exact
                    render={props => <Claims
                        {...props}
                        claims={this.state.claimsList}
                        operators={this.state.operators}
                        employeeIdForClaims={this.state.employeeIdForClaims}
                        setEmployeeIdForClaims={this.setEmployeeIdForClaims}
                        reloadOperators={this.fetchOperators}
                        refreshInterval={this.refreshInterval}
                        setLoadingState={this.setLoadingState}
                    />}
                />

                <Route
                    path="/claim/:claimId" exact
                    render={props => <Claim
                        {...props}
                        claim={this.getDummyClaim()}
                        setLoadingState={this.setLoadingState}
                    />}
                />

            </div>

        </>);
    }

};