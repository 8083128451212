// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';

import 'react-dates/initialize';
import { DateRangePicker, DatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

import '../../styles/datepicker.css';


export default class DateChooser extends Component {

    state = {
        focusedInput: null,
    }

    momentToDateString = mom => {
        if (mom && mom.length > 0) {
            console.log('dateString', mom)
            return new Date(mom._d);
            const date = new Date(mom._d);
            let month = date.getMonth().toString();
            month = month.length === 1 ? '0' + month : month;
            const year = date.getFullYear().toString();
            return month + '/' + year;
        } else {
            const date = new Date();
            console.log('new date', date)
            return date;
        }
    }

    render = () => {
        return (
            <div>
                {!this.props.byMonth ? <DateRangePicker
                    startDate={this.props.startDate}
                    endDate={this.props.endDate}

                    // startDateId={'Hello'}
                    // endDateId={'World'}

                    onDatesChange={this.props.onDatesChange}
                    focusedInput={this.state.focusedInput}
                    onFocusChange={focusInput => this.setState({ focusedInput: focusInput })}

                    minDate={this.props.minDate}
                    maxDate={this.props.maxDate}
                    
                    numberOfMonths={this.props.numberOfMonths}
                    isOutsideRange={this.props.isOutsideRange}
                /> : <div>

                    {/* <DatePicker
                        selected={this.momentToDateString(this.props.startDate)}
                        onChange={date => this.props.onDatesChange(date, this.props.endDate)}
                        selectsStart
                        startDate={this.momentToDateString(this.props.startDate)}
                        endDate={this.momentToDateString(this.props.endDate)}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                    /> */}
                    {/* <DatePicker
                        selected={this.momentToDateString(this.props.endDate)}
                        // onChange={date => this.props.onDatesChange(this.props.startDate, date)}
                        selectsEnd
                        startDate={this.momentToDateString(this.props.startDate)}
                        endDate={this.momentToDateString(this.props.endDate)}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                    /> */}
                </div>}
            </div>
        )
    }
}