
import api from '../api';

export const fetchEmployersCount = async (query = {}) => {
    const res = await api.get('employer/getCountByQuery', {
        query: query
    }).catch(err => console.log('err getting Employers', err));

    return res;
}

export const fetchEmployers = async (query = {}, skip = null, limit = null) => {
    let object = {
        query: query,
    }

    if (skip !== null && limit !== null) {
        object.skip = parseInt(skip);
        object.limit = parseInt(limit);
    }

    const res = await api.get('employer/getByQuery', object).catch(err => console.log('err getting Employers', err));
    return res;
}

export const fetchEmployeesCount = async (query = {}) => {
    const res = await api.get('employee/getCountByQuery', {
        query: query
    }).catch(err => console.log('err getting Employees', err));

    return res;
}

export const fetchEmployees = async (query = {}, skip = null, limit = null) => {
    let object = {
        query: query,
    }

    if (skip !== null && limit !== null) {
        object.skip = parseInt(skip);
        object.limit = parseInt(limit);
    }

    const res = await api.get('employee/getByQuery', object).catch(err => console.log('err getting Employees', err));
    return res;
}

export const fetchPlanById = async id => {
    return await api.get('plan/get', { id: id }).catch(err => console.log('err getting plan by id', err));
}

export const fetchEmployerById = async id => {
    return await api.get('employer/get', { id: id }).catch(err => console.log('err getting employer by id', err));
}

export const fetchEmployeeById = async id => {
    return await api.get('employee/get', { id: id }).catch(err => console.log('err getting employee by id', err));
}

export const fetchClaimsCount = async (query = {}) => {
    const res = await api.get('claim/getCountByQuery', {
        query: query
    }).catch(err => console.log('err getting claims', err));

    return res;
}

export const fetchClaims = async (query = {}, skip=null, limit=null) => {
    let object = {
        query: query,
    }

    if (skip !== null && limit !== null) {
        console.log('fetching claims: skip', skip, 'limit', limit)
        object.skip = parseInt(skip);
        object.limit = parseInt(limit);
    }

    let res = await api.get('claim/getByQuery', object).catch(err => console.log('err getting claims', err));

    return res;
}

export const fetchClaimById = async id => {
    return await api.get('claim/get', { id: id }).catch(err => console.log('err getting claim by id', err));
}

export const fetchPlanGraphic  = async (id, graphic) => {
    let docStream = await api.get("plan/download", { id: id, key: graphic }, null, "blob")
    const blob = new Blob(
        [docStream],
        { type: "image/png" }
    );
    return blob;
}


export const changeClaimOperator = async (claimId, operatorId) => {
    await api.post('claim/update', {
        id: claimId,
        diff: {
            operator: operatorId,
        }
    });
}

export const flagClaim = async (claimId) => {
    await api.get('claim/flag', {
        id: claimId
    });
}

// create functions
export const createEmployer = async (object) => {
    return await api.post('employer/create', object);
}

export const createPlan = async (object) => {
    return await api.post('plan/create', object);
}

export const createClaim = async (object) => {
    return await api.post('claim/createByAdmin', object);
}

export const createOperator = async (object) => {
    return await api.post('operator/create', object);
}

// update functions
export const updateEmployer = async (employerId, diff) => {
    await api.post('employer/update', {
        id: employerId,
        diff: diff
    });
}

export const updateEmployee = async (employeeId, diff) => {
    await api.post('employee/update', {
        id: employeeId,
        diff: diff
    });
}

export const updateClaim = async (claimId, diff) => {
    await api.post('claim/update', {
        id: claimId,
        diff: diff
    });
}

// remove functions
export const removeEmployer = async id => {
    await api.post('employer/remove', {
        id: id
    });
}

export const removePlan = async id => {
    await api.post('plan/remove', {
        id: id
    });
}

export const removeClaim = async id => {
    await api.post('claim/remove', {
        id: id
    });
}

export const removeEmployee = async id => {
    await api.post('employee/remove', {
        id: id
    });
}

// email functions
export const sendEmailToHR = async (email, planData) => {
    console.log(email, planData)
    await api.post('email/sendToHR', {
        email: email,
        planData,
    });
}

export const sendReceipt = async (data) => {
    return await api.post('claim/sendReceipt', data);
}
