// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React from 'react';
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable';
import ReactLoading from "react-loading";
import Toggle from 'react-toggle';

import DateChooser from './DateChooser';
import { isInclusivelyBeforeDay } from 'react-dates';
import moment from 'moment';

import '../../styles/table.css'
import 'react-toggle/style.css';

import { changeClaimOperator, fetchEmployeeById, fetchEmployerById, removeClaim, createOperator } from '../../helpers/dataHandler';
import { promptForDelete, getLoadingGraphic } from '../../helpers/helperFunctions';

export default class ClaimTable extends React.Component {

    pageSizeOptions = [15,30,50,100];

    state = {
        loading: true,

        pagination: {
            skip: 0,
            limit: this.pageSizeOptions[0],
        },

        query: "",
        filteredData: [],
        filters: {
            claimNumber: '',
            employerName: '',
            planName: '',
            employeeName: '',
            status: '',
            operator: '',
        },

        assigneeFocused: false,
        removeBtnFocused: false,
        hoveredRowIdx: -1,

        sortField: '',
        sortReversed: false,
        sortHoverField: '',
        
        submitDateByMonth: false,
        submitStartDate: null,
        submitEndDate: null,
        completeDateByMonth: false,
        completeStartDate: null,
        completeEndDate: null,
    }

    timeScaleOptions = [
        { label: 'Past 3 Months', value: 3 },
        { label: 'Past 6 Months', value: 6 },
        { label: 'Past 9 Months', value: 9 },
        { label: 'Past 1 Year', value: 12 },
        { label: 'All Time', value: -1 },
    ]

    componentDidMount = async () => {
        if (this.props.employeeIdForClaims) {
            await this.processEmployeeForClaims();
        }
        
        await this.filterData();
        await this.resetPagination();
        this.setState({loading: false})
    }
    
    componentDidUpdate = async (prevProps) => {
        if (prevProps.data && prevProps.data.length !== this.props.data.length) 
            this.resetPagination();

        if (this.props.data && JSON.stringify(this.props.data) !== JSON.stringify(prevProps.data)) {
            this.filterData()
        }
    }

    processEmployeeForClaims = async () => {

        //get employee
        const employee = await fetchEmployeeById(this.props.employeeIdForClaims);
        const employer = await fetchEmployerById(employee.employer);

        //set filter
        let filters = {...this.state.filters};

        if (employer)
            filters.employerName = employer.name;
        filters.employeeName = employee.name;

        await this.setState({filters: filters})
    }

    resetPagination = () => {
        this.setState({pagination: {
            skip: 0,
            limit: this.state.pagination.limit,
        }});
    }

    getOperator = id => {
        const operList = this.props.operators.filter(oper => oper._id === id);
        if (operList && operList.length > 0)
            return { id: operList[0]._id, name: operList[0].name }
        else
            return { id: null, name: 'None' }
    }

    filterData = async (field, newValue) => {
        if (field) {
            await this.setState({
                filters: {
                    ...this.state.filters,
                    [field]: newValue
                }
            })
            this.resetPagination();
        }

        const keys = Object.keys(this.state.filters);

        let claims = [...this.props.data];

        for (let i=0; i<keys.length; i++) {
            const value = this.state.filters[keys[i]]
            if (!value || value.length === 0)
                continue;

            if (keys[i] === 'operator') {
                const operatorList = this.props.operators.filter(oper => oper.name.toLowerCase().includes(value.toLowerCase()));
                claims = claims.filter(item => operatorList.map(oper => oper._id).includes(item.operator));
            } else {
                claims = claims.filter(item => item[keys[i]].toString().toLowerCase().includes(value.toLowerCase()))
            }
        }

        // date filters
        if (this.state.submitStartDate)
            claims = claims.filter(clm => this.getDateString(clm.createdDate) >= this.getDateString(this.state.submitStartDate._d));
        if (this.state.submitEndDate)
            claims = claims.filter(clm => this.getDateString(clm.createdDate) <= this.getDateString(this.state.submitEndDate._d));
        if (this.state.completeStartDate)
            claims = claims.filter(clm => clm.receiptSentDate && this.getDateString(clm.receiptSentDate) >= this.getDateString(this.state.completeStartDate._d));
        if (this.state.completeEndDate)
            claims = claims.filter(clm => clm.receiptSentDate && this.getDateString(clm.receiptSentDate) <= this.getDateString(this.state.completeEndDate._d));
        

        this.setState({filteredData: claims});
    }

    getDateString = _date => {
        const date = new Date(_date);
        return date.toISOString().split('T')[0];
    }

    hasMorePages = (direction, dataLen) => {
        let hasMore;
        if (direction === -1)
            hasMore = this.state.pagination.skip !== 0;
        else
            hasMore = this.state.pagination.skip + this.state.pagination.limit < dataLen;
        return hasMore;
    }

    // pagination
    fetchNext = async (direction, dataLen) => {
        let page = { ...this.state.pagination };

        if (!this.hasMorePages(direction, dataLen))
            return;
                    
        page.skip += direction * this.state.pagination.limit;
        await this.setState({ pagination: page });
    }

    setNewPageSize = async newPageSize => {
        await this.setState({
            pagination: {
                skip: 0,
                limit: newPageSize,
            }
        });
    }

    handleMouseHover = async (idx, entering, evt) => {
        let newState = { hoveredRowIdx: idx };
        this.setState(newState);
    }

    removeElementFromDB = async (id, name) => {
        if (promptForDelete(name)) {
            this.props.setLoadingState(true);
            await removeClaim(id);
            await this.props.reloadData();
            this.props.setLoadingState(false);
        }
    }

    changeOperator = async (claimId, newOperator) => {
        this.props.setLoadingState(true);
        if (!this.props.operators.map(oper => oper._id).includes(newOperator)) {
            //create new operator
            newOperator = await createOperator({ object: {name: newOperator} })
            await this.props.reloadOperators();
        }            
        await changeClaimOperator(claimId, newOperator);
        await this.props.reloadData();
        this.props.setLoadingState(false);
    }

    sortData = data => {
        let _data = [...data];
        if (_data.length > 0 && Object.keys(_data[0]).includes(this.state.sortField)) {
            
            if (this.state.sortField === 'operator') {
                if (!this.props.operators || this.props.operators.length === 0)
                    return data;

                _data.sort((a,b) => {                    
                    const aName = this.props.operators.filter(op => op._id === a.operator)[0].name;
                    const bName = this.props.operators.filter(op => op._id === b.operator)[0].name;
                    return aName < bName ? -1 : 1;
                });
            } else {
                _data.sort((a,b) => {
                    if (a[this.state.sortField] < b[this.state.sortField])
                        return -1;
                    else
                        return 1
                })
            }

            if (this.state.sortReversed) 
                _data.reverse();
        }
        return _data;
    }

    getSortArrow = propName => {        
        if (this.state.sortHoverField === propName && this.state.sortField !== propName) {
            return (
                <span 
                    style={{position: 'relative', left: '5px', color: 'rgba(0,0,0,0.2)'}}
                    >▲</span>
                // <span>▼</span>
            )
        } else if (this.state.sortField === propName) {
            return (
                <span 
                    style={{position: 'relative', left: '5px'}}
                >
                    {this.state.sortReversed ? '▼' : '▲'}
                </span>
                // <span>▼</span>
            )
        } else return ''
    }

    momentToDateString = mom => {
        const date = new Date(mom._d);
        let month = date.getMonth().toString();
        month = month.length === 1 ? '0' + month : month;
        const year = date.getFullYear().toString();
        return month + '/' + year;
    }

    setSubmitDates = async ({ startDate, endDate }) => {
        console.log('start', startDate)
        console.log('end', endDate)
        
        if (startDate) {
            console.log('date', this.momentToDateString(startDate))
        }

        await this.setState({
            submitStartDate: startDate,
            submitEndDate: endDate
        });
        this.filterData();
    }

    setCompleteDates = async ({ startDate, endDate }) => {

        await this.setState({
            completeStartDate: startDate,
            completeEndDate: endDate
        });
        this.filterData();
    }

    render = () => {

        let data = this.state.filteredData ? this.state.filteredData : this.props.data;
        let dataLen = data.length;
        const pageData = data.slice(this.state.pagination.skip, this.state.pagination.skip + this.state.pagination.limit);
        const sortedData = this.sortData(pageData)
        
        return (
            <div 
                className='tableOuterComponent'
                onMouseLeave={() => this.handleMouseHover(-1, false)}
            >
            <div className='tableComponent'>
                <h2 className="sub-header">{this.props.title} ({dataLen})</h2>

                <div className='selectOptions'>

                    <Select
                        key={'dateRangeSelect'}
                        className='dateRangeSelect_container'
                        classNamePrefix='dateRangeSelect'
                        value={this.timeScaleOptions.filter(opt => opt.value === this.props.currentTimeScale)}
                        options={this.timeScaleOptions}
                        onChange={async newTimeScale => this.props.setNewTimeScale(newTimeScale.value)}
                    />
                    
                    <Select
                        key={'tablePageSizeSelect'}
                        className='tablePageSizeSelect_container'
                        classNamePrefix='tablePageSizeSelect'
                        value={{ label: 'Page size: ' + this.state.pagination.limit, value: this.state.pagination.limit}}
                        options={this.pageSizeOptions.map(opt => {return {label: 'Page size: ' + opt, value: opt}})}
                        onChange={async newPageSize => this.setNewPageSize(newPageSize.value)}
                    />

                    {data && data.length > 0 && <span className='pageCounter'>Page {this.state.pagination.skip / this.state.pagination.limit + 1} of {this.state.pagination.limit ? Math.ceil(data.length / this.state.pagination.limit) : '?'}</span>}

                </div>

                <div className='dateFilterContainer'>

                    <div className='dateFilterObject dateFilterObject_submit'>
                        <div className='dateFilterHeader'>
                            Claim Submit Date &nbsp;&nbsp;&nbsp;&nbsp;
                        </div>
                        <div className='dateFilterClearBtn clickable'
                            onClick={async () => {
                                await this.setState({submitStartDate: null, submitEndDate: null})
                                this.filterData();
                            }}
                        >Clear</div>

                        {/* <div className='toggleByMonth'>
                            <Toggle 
                                checked={this.state.submitDateByMonth}
                                name='By Month'
                                onChange={() => this.setState({submitDateByMonth: !this.state.submitDateByMonth})}
                            />
                        </div> */}
                        
                        <div className='dateFilter'>
                            <DateChooser 
                                byMonth={this.state.submitDateByMonth}
                                startDate = {this.state.submitStartDate}
                                endDate = {this.state.submitEndDate}
                                onDatesChange = {this.setSubmitDates}
                                numberOfMonths={3}
                                isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
                            />
                        </div>
                    </div>
                    
                    <div className='dateFilterObject dateFilterObject_complete'>
                        <div className='dateFilterHeader'>
                            Claim Complete Date
                        </div>
                        <div className='dateFilterClearBtn clickable'
                            onClick={async () => {
                                await this.setState({completeStartDate: null, completeEndDate: null})
                                this.filterData();
                            }}
                        >Clear</div>

                        {/* <div className='toggleByMonth'>
                            <Toggle 
                                checked={this.state.completeDateByMonth}
                                name='By Month'
                                onChange={() => this.setState({completeDateByMonth: !this.state.completeDateByMonth})}
                            />
                        </div> */}
                        
                        <div className='dateFilter'>
                            <DateChooser 
                                byMonth={this.state.completeDateByMonth}
                                startDate = {this.state.completeStartDate}
                                endDate = {this.state.completeEndDate}
                                onDatesChange = {this.setCompleteDates}
                                numberOfMonths={3}
                                isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
                            />
                        </div>
                    </div>

                </div>

                <div className='pageControls'>
                    {this.hasMorePages(-1, dataLen) && <span className='pageControlBtn clickable' onClick={() => this.fetchNext(-1, dataLen)} style={{ float: 'left', cursor: 'pointer' }}>{'< Previous'}</span>}

                    {this.hasMorePages(1, dataLen) && <span className='pageControlBtn clickable' onClick={() => this.fetchNext(1, dataLen)} style={{ float: 'right', cursor: 'pointer' }}>{'Next >'}</span>}
                </div>

                {this.props.data && <table className="tableContainer">
                    <thead>
                        <tr>
                            <th>
                                <div
                                    className='tableHeaderItem'
                                    onMouseEnter={() => this.setState({ sortHoverField: 'claimNumber' })}
                                    onMouseLeave={() => this.setState({ sortHoverField: '' })}
                                    onClick={() => this.setState({sortField: 'claimNumber', sortReversed: this.state.sortField === 'claimNumber' ? !this.state.sortReversed : false})}
                                >
                                    Claim Number
                                    {this.getSortArrow('claimNumber')}
                                </div>

                                <div className='searchBoxContainer'>
                                    <input
                                        className={'searchBox ' + this.state.filters.claimNumber.length > 0 ? ' activeSearchBox' : ''}
                                        onChange={evt => this.filterData('claimNumber', evt.target.value)}
                                        placeholder='Type Number'
                                        value={this.state.filters.claimNumber}                                    
                                    />
                                </div>
                            </th>
                            <th>
                                <div
                                    className='tableHeaderItem'
                                    onMouseEnter={() => this.setState({ sortHoverField: 'employerName' })}
                                    onMouseLeave={() => this.setState({ sortHoverField: '' })}
                                    onClick={() => this.setState({sortField: 'employerName', sortReversed: this.state.sortField === 'employerName' ? !this.state.sortReversed : false})}
                                >
                                    Employer Name
                                    {this.getSortArrow('employerName')}
                                </div>

                                <div className='searchBoxContainer'>
                                    <input
                                        className={'searchBox ' + this.state.filters.employerName.length > 0 ? ' activeSearchBox' : ''}
                                        onChange={evt => this.filterData('employerName', evt.target.value)}
                                        placeholder='Type Employer'
                                        value={this.state.filters.employerName}
                                        // style={{color: (this.state.filters.employerName.length > 0 ? 'rgb(39,66,124)' : 'gray')}}
                                    />
                                </div>
                            </th>
                            <th>
                                <div
                                    className='tableHeaderItem'
                                    onMouseEnter={() => this.setState({ sortHoverField: 'planName' })}
                                    onMouseLeave={() => this.setState({ sortHoverField: '' })}
                                    onClick={() => this.setState({sortField: 'planName', sortReversed: this.state.sortField === 'planName' ? !this.state.sortReversed : false})}
                                >
                                    Plan
                                    {this.getSortArrow('planName')}
                                </div>

                                <div className='searchBoxContainer'>
                                    <input
                                        className={'searchBox ' + this.state.filters.planName.length > 0 ? ' activeSearchBox' : ''}
                                        onChange={evt => this.filterData('planName', evt.target.value)}
                                        placeholder='Type Plan'
                                        value={this.state.filters.planName}
                                        // style={{color: (this.state.filters.planName.length > 0 ? 'rgb(39,66,124)' : 'gray')}}
                                    />
                                </div>
                            </th>
                            <th>
                                <div
                                    className='tableHeaderItem'
                                    onMouseEnter={() => this.setState({ sortHoverField: 'employeeName' })}
                                    onMouseLeave={() => this.setState({ sortHoverField: '' })}
                                    onClick={() => this.setState({sortField: 'employeeName', sortReversed: this.state.sortField === 'employeeName' ? !this.state.sortReversed : false})}
                                >
                                    Employee Name
                                    {this.getSortArrow('employeeName')}
                                </div>

                                <div className='searchBoxContainer'>
                                    <input
                                        className={'searchBox ' + this.state.filters.employeeName.length > 0 ? ' activeSearchBox' : ''}
                                        onChange={evt => this.filterData('employeeName', evt.target.value)}
                                        placeholder='Type Employee'
                                        value={this.state.filters.employeeName}
                                        // style={{color: (this.state.filters.employeeName.length > 0 ? 'rgb(39,66,124)' : 'gray')}}
                                    />
                                </div>
                            </th>
                            <th>
                                <div
                                    className='tableHeaderItem'
                                    onMouseEnter={() => this.setState({ sortHoverField: 'status' })}
                                    onMouseLeave={() => this.setState({ sortHoverField: '' })}
                                    onClick={() => this.setState({sortField: 'status', sortReversed: this.state.sortField === 'status' ? !this.state.sortReversed : false})}
                                >
                                    Status
                                    {this.getSortArrow('status')}
                                </div>

                                <div className='searchBoxContainer'>
                                    <input
                                        className={'searchBox ' + this.state.filters.status.length > 0 ? ' activeSearchBox' : ''}
                                        onChange={evt => this.filterData('status', evt.target.value)}
                                        placeholder='Type Status'
                                        value={this.state.filters.status}
                                        // style={{color: (this.state.filters.status.length > 0 ? 'rgb(39,66,124)' : 'gray')}}
                                    />
                                </div>
                            </th>
                            <th>
                                <div
                                    className='tableHeaderItem'
                                    onMouseEnter={() => this.setState({ sortHoverField: 'operator' })}
                                    onMouseLeave={() => this.setState({ sortHoverField: '' })}
                                    onClick={() => this.setState({sortField: 'operator', sortReversed: this.state.sortField === 'operator' ? !this.state.sortReversed : false})}
                                >
                                    Assignee
                                    {this.getSortArrow('operator')}
                                </div>

                                <div className='searchBoxContainer'>
                                    <input
                                        className={'searchBox ' + '' + (this.state.filters.operator.length > 0 ? ' activeSearchBox' : '')}
                                        onChange={evt => this.filterData('operator', evt.target.value)}
                                        placeholder='Type Assignee'
                                        value={this.state.filters.operator}
                                        // style={{color: (this.state.filters.operator.length > 0 ? 'rgb(39,66,124)' : 'gray')}}
                                    />
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedData && sortedData.length > 0 && sortedData.map((item, idx) => {
                            const operatorList = this.props.operators.map((oper) => { return {label: oper.name, value: oper._id} })
                            return (
                                <tr 
                                    key={'dataItem_' + idx}
                                    onClick={() => {
                                        if (!this.state.assigneeFocused && !this.state.removeBtnFocused)
                                            this.props.history.push('/claim/' + item._id)
                                    }}
                                    onMouseEnter={(evt) => this.handleMouseHover(idx, true, evt)}
                                >
                                    <td>
                                        {this.state.hoveredRowIdx === idx && <img
                                            className='removeTableElement removeTableElement_claimTable clickable'
                                            title='Permenantly Remove'
                                            alt="remove"
                                            src='/icons/close.svg'
                                            onClick={() => this.removeElementFromDB(item._id, item.claimNumber)}
                                            onMouseEnter={() => this.setState({ removeBtnFocused: true })}
                                            onMouseLeave={() => this.setState({ removeBtnFocused: false })}
                                        />} 
                                        {item.claimNumber}
                                    </td>
                                    <td>{item.employerName}</td>
                                    <td>{item.planName}</td>
                                    <td>{item.employeeName}</td>
                                    <td>{item.status}</td>
                                    <td style={{position: 'relative', paddingRight: '60px'}}>
                                        <CreatableSelect
                                            key={'claimAssigneeSelect_'+idx}
                                            className='claimAssigneeSelect_container'
                                            classNamePrefix='claimAssigneeSelect'
                                            options={operatorList}
                                            onFocus={() => this.setState({assigneeFocused: true})}
                                            onBlur={() => this.setState({assigneeFocused: false})}
                                            onChange={async newOperator => this.changeOperator(item._id, newOperator.value)}
                                            value={{ 
                                                label: this.getOperator(item.operator).name, 
                                                value: this.getOperator(item.operator).id
                                            }}
                                        />
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>}

                {!this.state.loading && (!data || data.length === 0) && <div className='noResultsFound'>No results found.</div>}
            </div>
            </div>
        );
    }
}