// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React from 'react';
import '../../styles/employers.css'

import EmployerTable from '../subComponents/EmployerTable';

import api from "../../api";

import { fetchEmployers, fetchEmployees, createEmployer, removeEmployer } from '../../helpers/dataHandler';
import { employees } from '../../constants/data';

export default class Employers extends React.Component {

    state = {

        employersCount: 0,
        employers: [],
    }

    fetchInterval = null;

    componentDidMount = async () => {
        this.props.setLoadingState(true);
        await api.post("self/adminLogin").catch(() => {
            this.props.history.push("/login");
            return
        })

        await this.deleteBlankEmployers();
        await this.fetchData();
        this.props.setLoadingState(false);
    }

    fetchData = async () => {
        await this.fetchEmployers();

        this.fetchInterval = setInterval(this.fetchEmployers, this.props.refreshInterval ? this.props.refreshInterval : 60000);
    }

    componentWillUnmount = async () => {        
        if (this.fetchInterval)
            clearInterval(this.fetchInterval)
    }

    fetchEmployers = async () => {
        
        const employers = await fetchEmployers({});
        const employees = await fetchEmployees({});

        if (employers) {
            for (let i=0; i<employers.length; i++) {
                const numEmployees = employees.filter(emp => emp.employer === employers[i]._id).length;
                employers[i].numEmployees = numEmployees;
            }
            
            await this.setState({ employers: employers });
        }
    }

    deleteBlankEmployers = async () => {
        // delete employers with no name
        const employers = await fetchEmployers();
        if (employers && employers.length > 0) {
            const blankEmployers = employers.filter(emp => !emp.name || emp.name.length === 0); 
            console.log(blankEmployers)
            await blankEmployers.map(async emp => { await removeEmployer(emp._id) })
        }
    }

    addNewEmployer = async () => {

        // create empty employer
        const newEmployer = await createEmployer({ object: {}})

        // send to that page with editing activated
        this.props.history.push('employer/' + newEmployer._id, {createNew: true});
    }

    render = () => {
        return (
            <div>
                <h3>Employers</h3>

                <div>
                    <div 
                        style={{zIndex: 1}}
                        className='addNewEmployer clickable' 
                        onClick={this.addNewEmployer}
                    >Add New +</div>
                </div>

                <EmployerTable
                    data={this.state.employers}
                    history={this.props.history}
                    reloadData={this.fetchData}
                    setLoadingState={this.props.setLoadingState}
                />
            </div>
        )
    }

};