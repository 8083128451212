// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React from 'react';
import Select from 'react-select'
import ReactLoading from "react-loading";

import MultiSlider from './MultiSlider';
import DoubleInput from './DoubleInput';

import '../../styles/table.css'

import { removeEmployer } from '../../helpers/dataHandler';
import { promptForDelete, normalizeDomain } from '../../helpers/helperFunctions';

export default class EmployerTable extends React.Component {

    pageSizeOptions = [15, 30, 50, 100];

    state = {
        pagination: {
            skip: 0,
            limit: this.pageSizeOptions[0],
        },

        query: "",
        filteredData: [],
        filters: {
            employerName: '',
            planNames: [],
            numEmployees: '',
            numEmployeeRange: [],
        },
        hoveredRowIdx: -1,
        removeBtnFocused: false,
        numEmployeesDomain: [],

        sortField: '',
        sortReversed: false,
        sortHoverField: '',
    }

    componentDidMount = async () => {
        await this.setState({numEmployeesDomain: this.setNumEmployeesDomain(this.props.data)})
        this.filterData();
        await this.resetPagination();
    }

    componentDidUpdate = async (prevProps) => {
        if (prevProps.data && this.props.data.length !== prevProps.data.length) {
            await this.setState({numEmployeesDomain: this.setNumEmployeesDomain(this.props.data)})
            await this.resetPagination();
            this.filterData();
        }
        else if (JSON.stringify(this.props.data) !== JSON.stringify(prevProps.data)) {
            await this.setState({numEmployeesDomain: this.setNumEmployeesDomain(this.props.data)})
            this.filterData();
        }
    }

    setNumEmployeesDomain = (data) => {
        let domain;
        if (!data || data.length === 0)
            domain = [0,0]
        else
            domain = [Math.min(...data.map(d => d.numEmployees)), Math.max(...data.map(d => d.numEmployees))]

        return domain;
    }

    resetPagination = () => {
        this.setState({
            pagination: {
                skip: 0,
                limit: this.state.pagination.limit,
            }
        });
    }

    filterData = async (field, newValue) => {
        if (field) {
            console.log('newValue', newValue)
            if (field === 'numEmployeeRange') {
                newValue[0] = !newValue[0] || newValue[0].length === 0 || isNaN(newValue[0]) ? this.state.numEmployeesDomain[0] : parseInt(newValue[0]);
                newValue[1] = !newValue[1] || newValue[1].length === 0 || isNaN(newValue[1]) ? this.state.numEmployeesDomain[1] : parseInt(newValue[1]);
            }
            console.log('new', newValue)
                
            await this.setState({
                filters: {
                    ...this.state.filters,
                    [field]: newValue
                }
            })
        }

        const keys = Object.keys(this.state.filters);
        let data = [...this.props.data];

        for (let i=0; i<keys.length; i++) {
            const value = this.state.filters[keys[i]]
            
            try {
                if (keys[i] === 'numEmployeeRange') {
                    if (!isNaN(this.state.filters.numEmployeeRange[0]) && !isNaN(this.state.filters.numEmployeeRange[1]))
                        data = data.filter(item => item.numEmployees >= value[0] && item.numEmployees <= value[1]);
                } else {

                    if (!value || value.length === 0)
                        continue;

                    if (keys[i] === 'planNames') {
                        data = data.filter(item => item[keys[i]].join().toLowerCase().includes(value.toLowerCase()));
                    } else {
                        data = data.filter(item => item[keys[i]].toString().toLowerCase().includes(value.toLowerCase()))
                    }
                }
            }
            catch (err) {
                console.log('error filtering employer table data:', err)
            }
        }

        this.setState({filteredData: data});        
    }

    onUpdate_numEmployeesSlider = (newRange) => {
        this.filterData('numEmployeeRange', newRange);
    }

    hasMorePages = (direction, dataLen) => {
        let hasMore;
        if (direction === -1)
            hasMore = this.state.pagination.skip !== 0;
        else
            hasMore = this.state.pagination.skip + this.state.pagination.limit < dataLen;
        return hasMore;
    }

    // pagination
    fetchNext = async (direction, dataLen) => {
        let page = { ...this.state.pagination };

        if (!this.hasMorePages(direction, dataLen))
            return;

        page.skip += direction * this.state.pagination.limit;
        await this.setState({ pagination: page });
    }

    setNewPageSize = async newPageSize => {
        await this.setState({
            pagination: {
                skip: 0,
                limit: newPageSize,
            }
        });
    }

    handleMouseHover = async (idx, entering, evt) => {
        let newState = { hoveredRowIdx: idx };
        this.setState(newState);
    }

    removeElementFromDB = async (id, name) => {
        if (promptForDelete(name)) {
            this.props.setLoadingState(true);
            await removeEmployer(id);
            await this.props.reloadData();
            this.props.setLoadingState(false);
        }
    }

    sortData = data => {
        let _data = [...data];
        if (_data.length > 0 && Object.keys(_data[0]).includes(this.state.sortField)) {
            
            if (this.state.sortField === 'operator') {
                if (!this.props.operators || this.props.operators.length === 0)
                    return data;

                _data.sort((a,b) => {                    
                    const aName = this.props.operators.filter(op => op._id === a.operator)[0].name;
                    const bName = this.props.operators.filter(op => op._id === b.operator)[0].name;
                    return aName < bName ? -1 : 1;
                });
            } else {
                _data.sort((a,b) => {
                    if (a[this.state.sortField] < b[this.state.sortField])
                        return -1;
                    else
                        return 1
                })
            }

            if (this.state.sortReversed) 
                _data.reverse();
        }
        return _data;
    }

    getSortArrow = propName => {        
        if (this.state.sortHoverField === propName && this.state.sortField !== propName) {
            return (
                <span 
                    style={{position: 'relative', left: '5px', color: 'rgba(0,0,0,0.2)'}}
                    >▲</span>
                // <span>▼</span>
            )
        } else if (this.state.sortField === propName) {
            return (
                <span 
                    style={{position: 'relative', left: '5px'}}
                >
                    {this.state.sortReversed ? '▼' : '▲'}
                </span>
                // <span>▼</span>
            )
        } else return ''
    }

    render = () => {

        let data = this.state.filteredData ? this.state.filteredData : this.props.data;
        const pageData = data.slice(this.state.pagination.skip, this.state.pagination.skip + this.state.pagination.limit);
        const sortedData = this.sortData(pageData)

        return (
            <div
                className='tableOuterComponent'
                onMouseLeave={() => this.handleMouseHover(-1, false)}
            >
            <div className='tableComponent'>
                
                <h2 className="sub-header">Current Employers ({this.state.filteredData.length})</h2>

                <Select
                    key={'tablePageSizeSelect'}
                    className='tablePageSizeSelect_container'
                    classNamePrefix='tablePageSizeSelect'
                    value={{ label: 'Page size: ' + this.state.pagination.limit, value: this.state.pagination.limit }}
                    options={this.pageSizeOptions.map(opt => { return { label: 'Page size: ' + opt, value: opt } })}
                    onChange={async newPageSize => this.setNewPageSize(newPageSize.value)}
                />

                {data && data.length > 0 && <span className='pageCounter'>Page {this.state.pagination.skip / this.state.pagination.limit + 1} of {this.state.pagination.limit ? Math.ceil(data.length / this.state.pagination.limit) : '?'}</span>}

                <div className='pageControls'>
                    {this.hasMorePages(-1, data.length) && <span className='pageControlBtn clickable' onClick={() => this.fetchNext(-1, data.length)} style={{ float: 'left', cursor: 'pointer' }}>{'< Previous'}</span>}

                    {this.hasMorePages(1, data.length) && <span className='pageControlBtn clickable' onClick={() => this.fetchNext(1, data.length)} style={{ float: 'right', cursor: 'pointer' }}>{'Next >'}</span>}
                </div>

                {/* <table className="tableContainer employerTable">
                    <thead className="employer-thead"> */}

                {this.props.data && <table className="tableContainer">
                    <thead className ="">
                        <tr>
                            <th className='tableHeader'>
                                <div
                                    className='tableHeaderItem'
                                    onMouseEnter={() => this.setState({ sortHoverField: 'name' })}
                                    onMouseLeave={() => this.setState({ sortHoverField: '' })}
                                    onClick={() => this.setState({sortField: 'name', sortReversed: this.state.sortField === 'name' ? !this.state.sortReversed : false})}
                                >
                                    Employer Name
                                    {this.getSortArrow('name')}
                                </div>
                                <div className='searchBoxContainer'>
                                    <input
                                        onChange={evt => this.filterData('name', evt.target.value)}
                                        placeholder='Type Employer'
                                    />
                                </div>
                            </th>
                            <th className='tableHeader'>
                                {/* <div
                                    className='tableHeaderItem'
                                    onMouseEnter={() => this.setState({ sortHoverField: 'claimNumber' })}
                                    onMouseLeave={() => this.setState({ sortHoverField: '' })}
                                    onClick={() => this.setState({sortField: 'claimNumber', sortReversed: this.state.sortField === 'claimNumber' ? !this.state.sortReversed : false})}
                                > */}
                                    Plan
                                    {/* {this.getSortArrow('claimNumber')} */}
                                {/* </div> */}
                                <div className='searchBoxContainer'>
                                    <input
                                        onChange={evt => this.filterData('planNames', evt.target.value)}
                                        placeholder='Type Plan'
                                    />
                                </div>
                            </th>
                            <th className='tableHeader'>
                                <div
                                    className='tableHeaderItem'
                                    onMouseEnter={() => this.setState({ sortHoverField: 'numEmployees' })}
                                    onMouseLeave={() => this.setState({ sortHoverField: '' })}
                                    onClick={() => this.setState({sortField: 'numEmployees', sortReversed: this.state.sortField === 'numEmployees' ? !this.state.sortReversed : false})}
                                >
                                    # of Registered Employees
                                    {this.getSortArrow('numEmployees')}
                                </div>
                                <div className='searchBoxContainer'>
                                    <DoubleInput 
                                        onChange1={evt => this.filterData('numEmployeeRange', [evt.target.value, this.state.filters.numEmployeeRange[1]])}
                                        onChange2={evt => this.filterData('numEmployeeRange', [this.state.filters.numEmployeeRange[0], evt.target.value])}
                                        placeholder1='Min'
                                        placeholder2='Max'
                                    />
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedData && sortedData.length > 0 && sortedData.map((item, idx) => {
                            return (
                                <tr 
                                    key={'dataItem_' + idx}
                                    onClick={() => {
                                        if (!this.state.removeBtnFocused)
                                            this.props.history.push('/employer/' + item._id)
                                    }}
                                    onMouseEnter={() => this.handleMouseHover(idx, true)}
                                >
                                    <td style={{width: '33%'}}>
                                        {this.state.hoveredRowIdx === idx && <img
                                            className='removeTableElement removeTableElement_employerTable clickable'
                                            title='Permenantly Remove'
                                            alt="remove"
                                            src='/icons/close.svg'
                                            onClick={() => this.removeElementFromDB(item._id, item.name)}
                                            onMouseEnter={() => this.setState({ removeBtnFocused: true })}
                                            onMouseLeave={() => this.setState({ removeBtnFocused: false })}
                                        />}
                                        {item.name ? item.name : "__noName__"}
                                    </td>
                                    {item.planNames && item.planNames.length > 0 
                                        ? <td style={{width: '33%'}}>{item.planNames.map((p, idx) => {
                                            return (
                                                <div key={'planName_'+idx}>{p}</div>
                                            )
                                        })} </td> : <td></td>}
                                    <td style={{width: '33%'}}>{item.numEmployees ? item.numEmployees : 0}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>}
            </div>
            </div>
        );
    }

}