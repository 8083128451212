// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React from 'react';
import Select from 'react-select'
import ReactLoading from "react-loading";

import MultiSlider from './MultiSlider';
import DoubleInput from './DoubleInput';

import '../../styles/table.css'

import { removeEmployee } from '../../helpers/dataHandler';
import { promptForDelete, normalizeDomain } from '../../helpers/helperFunctions';


export default class EmployeeTable extends React.Component {

    pageSizeOptions = [15, 30, 50, 100];

    state = {
        pagination: {
            skip: 0,
            limit: this.pageSizeOptions[0],
        },

        query: "",
        filteredData: [],
        filters: {
            employeeName: '',
            numClaims: '',
            status: '',
            claimRange: [],
            minClaims: 0,
            maxClaims: 0,
        },
        hoveredRowIdx: -1,
        tableRowFocused: false,
        numClaimsDomain: [],

        sortField: '',
        sortReversed: false,
        sortHoverField: '',
    }

    componentDidMount = async () => {
        await this.setState({numClaimsDomain: this.setNumClaimsDomain(this.props.data)})
        this.filterData();
        await this.resetPagination();
    }

    componentDidUpdate = async (prevProps) => {
        if (prevProps.data && this.props.data.length !== prevProps.data.length) {
            await this.setState({numClaimsDomain: this.setNumClaimsDomain(this.props.data)})
            await this.resetPagination();
            this.filterData();
        }
        else if (JSON.stringify(this.props.data) !== JSON.stringify(prevProps.data)) {
            await this.setState({numClaimsDomain: this.setNumClaimsDomain(this.props.data)})
            this.filterData(); 
        }
    }

    setNumClaimsDomain = (data) => {
        let domain;
        if (!data || data.length === 0)
            domain = [0,0]
        else
            domain = [Math.min(...data.map(d => d.numClaims)), Math.max(...data.map(d => d.numClaims))]

        return domain;
    }

    resetPagination = () => {
        this.setState({
            pagination: {
                skip: 0,
                limit: this.state.pagination.limit,
            }
        });
    }

    filterData = async (field, newValue) => {
        
        if (field) {
            if (field === 'minClaims') 
                newValue = newValue.length === 0 || isNaN(newValue) ? this.state.numClaimsDomain[0] : parseInt(newValue).toString();
            if (field === 'maxClaims') 
                newValue = newValue.length === 0 || isNaN(newValue) ? this.state.numClaimsDomain[1] : parseInt(newValue).toString();
                
            await this.setState({
                filters: {
                    ...this.state.filters,
                    [field]: newValue
                }
            })
        }

        const keys = Object.keys(this.state.filters);        
        let data = [...this.props.data];

        for (let i=0; i<keys.length; i++) {
            const value = this.state.filters[keys[i]]
            
            if (keys[i] === 'minClaims' || keys[i] === 'maxClaims') {
                if (this.state.filters.minClaims)
                    data = data.filter(item => item.numClaims >= this.state.filters.minClaims);
                if (this.state.filters.maxClaims)
                    data = data.filter(item => item.numClaims <= this.state.filters.maxClaims);
            } else {

                if (!value ||value.length === 0)
                    continue;

                data = data.filter(item => item[keys[i]].toString().toLowerCase().includes(value.toLowerCase()));
            }
        }

        await this.setState({filteredData: data});
    }

    onUpdate_numClaimsSlider = (newRange) => {
        this.filterData('claimRange', newRange);
    }

    hasMorePages = (direction, dataLen) => {
        let hasMore;
        if (direction === -1)
            hasMore = this.state.pagination.skip !== 0;
        else
            hasMore = this.state.pagination.skip + this.state.pagination.limit < dataLen;
        return hasMore;
    }

    // pagination
    fetchNext = async (direction, dataLen) => {
        let page = { ...this.state.pagination };

        if (!this.hasMorePages(direction, dataLen))
            return;

        page.skip += direction * this.state.pagination.limit;
        await this.setState({ pagination: page });
    }

    setNewPageSize = async newPageSize => {
        await this.setState({
            pagination: {
                skip: 0,
                limit: newPageSize,
            }
        });
    }

    handleMouseHover = async (idx, entering, evt) => {
        let newState = { hoveredRowIdx: idx };
        this.setState(newState);
    }

    removeElementFromDB = async (id, name) => {
        if (promptForDelete(name)) {
            this.props.setLoadingState(true);
            await removeEmployee(id);
            await this.props.reloadData();
            this.props.setLoadingState(false);
        }
    }

    sortData = data => {
        let _data = [...data];
        if (_data.length > 0 && Object.keys(_data[0]).includes(this.state.sortField)) {
            
            if (this.state.sortField === 'operator') {
                if (!this.props.operators || this.props.operators.length === 0)
                    return data;

                _data.sort((a,b) => {                    
                    const aName = this.props.operators.filter(op => op._id === a.operator)[0].name;
                    const bName = this.props.operators.filter(op => op._id === b.operator)[0].name;
                    return aName < bName ? -1 : 1;
                });
            } else {
                _data.sort((a,b) => {
                    if (a[this.state.sortField] < b[this.state.sortField])
                        return -1;
                    else
                        return 1
                })
            }

            if (this.state.sortReversed) 
                _data.reverse();
        }
        return _data;
    }

    getSortArrow = propName => {        
        if (this.state.sortHoverField === propName && this.state.sortField !== propName) {
            return (
                <span 
                    style={{position: 'relative', left: '5px', color: 'rgba(0,0,0,0.2)'}}
                    >▲</span>
                // <span>▼</span>
            )
        } else if (this.state.sortField === propName) {
            return (
                <span 
                    style={{position: 'relative', left: '5px'}}
                >
                    {this.state.sortReversed ? '▼' : '▲'}
                </span>
                // <span>▼</span>
            )
        } else return ''
    }

    render = () => {

        let data = this.state.filteredData ? this.state.filteredData : this.props.data;
        const pageData = data.slice(this.state.pagination.skip, this.state.pagination.skip + this.state.pagination.limit);
        const sortedData = this.sortData(pageData);

        return (

            <div
                className='tableOuterComponent'
                onMouseLeave={() => this.handleMouseHover(-1, false)}
            >
                <div className="tableComponent">
                    <h2 className="sub-header">Find an Employee</h2>

                    <Select
                        key={'tablePageSizeSelect'}
                        className='tablePageSizeSelect_container'
                        classNamePrefix='tablePageSizeSelect'
                        value={{ label: 'Page size: ' + this.state.pagination.limit, value: this.state.pagination.limit }}
                        options={this.pageSizeOptions.map(opt => { return { label: 'Page size: ' + opt, value: opt } })}
                        onChange={async newPageSize => this.setNewPageSize(newPageSize.value)}
                    />

                    {data && data.length > 0 && <span className='pageCounter'>Page {this.state.pagination.skip / this.state.pagination.limit + 1} of {this.state.pagination.limit ? Math.ceil(data.length / this.state.pagination.limit) : '?'}</span>}

                    <div className='pageControls'>
                        {this.hasMorePages(-1, data.length) && <span className='pageControlBtn clickable' onClick={() => this.fetchNext(-1, data.length)} style={{ float: 'left', cursor: 'pointer' }}>{'< Previous'}</span>}

                        {this.hasMorePages(1, data.length) && <span className='pageControlBtn clickable' onClick={() => this.fetchNext(1, data.length)} style={{ float: 'right', cursor: 'pointer' }}>{'Next >'}</span>}
                    </div>

                    {this.props.data && <table className="tableContainer">
                        <thead>
                            <tr>
                                <th style={{width: 'calc(40%)'}}>
                                    <div
                                        className='tableHeaderItem'
                                        onMouseEnter={() => this.setState({ sortHoverField: 'name' })}
                                        onMouseLeave={() => this.setState({ sortHoverField: '' })}
                                        onClick={() => this.setState({sortField: 'name', sortReversed: this.state.sortField === 'name' ? !this.state.sortReversed : false})}
                                    >
                                        Employee Name
                                        {this.getSortArrow('name')}
                                    </div>

                                    <div className='searchBoxContainer'>
                                        <input
                                            onChange={evt => this.filterData('name', evt.target.value)}
                                            placeholder='Type Name'
                                        />  
                                    </div>
                                </th>
                                <th>
                                    <div
                                        className='tableHeaderItem'
                                        onMouseEnter={() => this.setState({ sortHoverField: 'numClaims' })}
                                        onMouseLeave={() => this.setState({ sortHoverField: '' })}
                                        onClick={() => this.setState({sortField: 'numClaims', sortReversed: this.state.sortField === 'numClaims' ? !this.state.sortReversed : false})}
                                    >
                                        # of Claims
                                        {this.getSortArrow('numClaims')}
                                    </div>

                                    <div className='searchBoxContainer'>
                                        <DoubleInput 
                                            onChange1={evt => this.filterData('minClaims', evt.target.value)}
                                            onChange2={evt => this.filterData('maxClaims', evt.target.value)}
                                            placeholder1='Min'
                                            placeholder2='Max'
                                        />
                                    </div>
                                
                                </th>
                                <th>
                                    <div
                                        className='tableHeaderItem'
                                        onMouseEnter={() => this.setState({ sortHoverField: 'status' })}
                                        onMouseLeave={() => this.setState({ sortHoverField: '' })}
                                        onClick={() => this.setState({sortField: 'status', sortReversed: this.state.sortField === 'status' ? !this.state.sortReversed : false})}
                                    >
                                        Status
                                        {this.getSortArrow('status')}
                                    </div>

                                    <div className='searchBoxContainer'>
                                        <input
                                            onChange={evt => this.filterData('status', evt.target.value)}
                                            placeholder='Type Status'
                                        />
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortedData && sortedData.length > 0 && sortedData.map((item, idx) => {
                                return (
                                    <tr 
                                        key={'dataItem_' + idx}
                                        onClick={async () => {
                                            if (!this.state.tableRowFocused) {
                                                await this.props.setEmployeeIdForClaims(item._id);
                                                this.props.history.push('/claims');
                                            }
                                        }}
                                        onMouseEnter={() => this.handleMouseHover(idx, true)}
                                        
                                    >
                                        <td style={{width: '33%', left: '10px', paddingLeft: "36px"}}>
                                            {this.state.hoveredRowIdx === idx && <img
                                                className='removeTableElement removeTableElement_employeeTable clickable'
                                                title='Permenantly Remove'
                                                alt="remove"
                                                src='/icons/close.svg'
                                                onClick={() => this.removeElementFromDB(item._id, item.name)}
                                                onMouseEnter={() => this.setState({ tableRowFocused: true })}
                                                onMouseLeave={() => this.setState({ tableRowFocused: false })}
                                            />}
                                            {this.state.hoveredRowIdx === idx && <img
                                                className='createNewClaim clickable'
                                                title='New claim for employee'
                                                alt="New claim for employee"
                                                src='/icons/add.svg'
                                                onClick={() => this.props.createNewClaim(item._id, item.name)}
                                                onMouseEnter={() => this.setState({ tableRowFocused: true })}
                                                onMouseLeave={() => this.setState({ tableRowFocused: false })}
                                            />}
                                            {item.name}
                                        </td>
                                        <td style={{width: '33%'}}>{item.numClaims}</td>
                                        <td style={{width: '33%', paddingRight: '60px'}}>{item.status}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>}
                </div>
            </div>
        );
    }

}